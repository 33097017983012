"use client";

import { createContext, useContext, type ReactNode, useMemo } from "react";
import useAuthentication from "@/hooks/useAuthentication";
import type { Address } from "viem";

type AuthContextType = {
  isLoggedIn: boolean;
  isAccountAuthorized: boolean;
  isOnCorrectNetwork: boolean;
  isUserInvestor: boolean;
  isConnected: boolean;
  address: Address;
  isAccessDeniedModalOpen: boolean;
  showAccessDeniedModal: () => void;
  closeAccessDeniedModal: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    isLoggedIn,
    isAccountAuthorized,
    isOnCorrectNetwork,
    isUserInvestor,
    isConnected,
    isAccessDeniedModalOpen,
    address,
    showAccessDeniedModal,
    closeAccessDeniedModal,
  } = useAuthentication();

  const value = useMemo(
    () => ({
      isLoggedIn,
      isAccountAuthorized,
      isOnCorrectNetwork,
      isUserInvestor,
      isConnected,
      address,
      isAccessDeniedModalOpen,
      showAccessDeniedModal,
      closeAccessDeniedModal,
    }),
    [
      isLoggedIn,
      isAccountAuthorized,
      isOnCorrectNetwork,
      isUserInvestor,
      isConnected,
      address,
      isAccessDeniedModalOpen,
      showAccessDeniedModal,
      closeAccessDeniedModal,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

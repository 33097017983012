import type { Address } from "viem";

interface ContractAddresses {
  USDC: Address;
  SHARES: Address;
}
export const CONTRACT_ADDRESSES: ContractAddresses = {
  USDC: process.env.NEXT_PUBLIC_USDC_CONTRACT_ADDRESS as Address,
  SHARES: process.env.NEXT_PUBLIC_SHARES_CONTRACT_ADDRESS as Address,
};

export const SAFES_ADDRESSES = process.env.NEXT_PUBLIC_SAFES_ADDRESSES?.split(
  ",",
) as Address[];

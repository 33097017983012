import { CONTRACT_ADDRESSES } from "@/constants/addresses";
import { PROTECTED_PATHS, URL_PATHS } from "@/constants/navigation";
import { useReadSharesHasRole } from "@/wagmi.generated";
import { useEffect, useState } from "react";
import { keccak256, toBytes, zeroAddress } from "viem";
import { useAccount, useConfig } from "wagmi";
import { usePathname, useRouter } from "next/navigation";

const INVESTOR_ROLE = keccak256(toBytes("INVESTOR"));

export default function useAuthentication() {
  const { chains: expectedChains } = useConfig();
  const pathname = usePathname();
  const router = useRouter();

  const { chain, address: accountAddress, isConnected } = useAccount();
  const [isAccessDeniedModalOpen, setIsAccessDeniedModalOpen] =
    useState<boolean>(false);
  const address = accountAddress ?? zeroAddress;

  const {
    data: isUserInvestor,
    isFetchedAfterMount,
    isError,
  } = useReadSharesHasRole({
    address: CONTRACT_ADDRESSES.SHARES,
    args: [INVESTOR_ROLE, address],
  });

  const isAccountAuthorized = isConnected && !!isUserInvestor;
  const isOnCorrectNetwork = chain?.id === expectedChains[0].id;

  useEffect(() => {
    const isProtectedPath = PROTECTED_PATHS.includes(pathname);

    const shouldShowAccessDenied =
      ((isFetchedAfterMount && (!isAccountAuthorized || !isOnCorrectNetwork)) ||
        isError) &&
      isProtectedPath;

    const isLoggedInAndAccessDeniedModalOpen =
      isAccountAuthorized && isOnCorrectNetwork && isAccessDeniedModalOpen;

    if (shouldShowAccessDenied) {
      setIsAccessDeniedModalOpen(true);
      router.replace(URL_PATHS.REPORT);
    } else if (isLoggedInAndAccessDeniedModalOpen) {
      setIsAccessDeniedModalOpen(false);
    }
  }, [
    pathname,
    isAccountAuthorized,
    isFetchedAfterMount,
    router,
    isError,
    isAccessDeniedModalOpen,
    isOnCorrectNetwork,
  ]);

  return {
    isLoggedIn: isAccountAuthorized && isOnCorrectNetwork,
    isAccountAuthorized,
    isOnCorrectNetwork,
    isUserInvestor: isUserInvestor || false,
    isConnected,
    address,
    isAccessDeniedModalOpen,
    showAccessDeniedModal: () => setIsAccessDeniedModalOpen(true),
    closeAccessDeniedModal: () => setIsAccessDeniedModalOpen(false),
  };
}
